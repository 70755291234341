import { useBreakpointValue } from '@chakra-ui/react'
import { ResponsivePie } from '@nivo/pie'
import { observer } from 'mobx-react'
import { nivoTheme } from 'containers/Dashboard/constant'
import { getProjectShortName } from 'containers/Dashboard/DashboardUser/utils'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
export const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  const isDesktop: boolean = useBreakpointValue({ base: false, md: true })
  let total = 0
  dataWithArc.forEach((datum) => {
    total += datum.value
  })

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: isDesktop ? '24px' : '16px',
        fontWeight: 600,
        fill: '#718096',
        lineHeight: isDesktop ? '32px' : '24px',
      }}>
      {total.toFixed(1)}
    </text>
  )
}
const ProjectWorkingTimeChart = () => {
  const { userProfileStore } = useStores()
  const { dashboardData } = userProfileStore
  const { projectWorkingTimePieData } = dashboardData
  const colorList = getValidArray(projectWorkingTimePieData).map((item) => item.color)
  return (
    <ResponsivePie
      theme={nivoTheme}
      data={getValidArray(projectWorkingTimePieData)}
      margin={{ top: 40, right: 160, bottom: 40, left: 20 }}
      innerRadius={0.5}
      id="label"
      sortByValue
      fit
      valueFormat={(e) => {
        const hour = Math.floor(Number(e))
        const min = Math.round((Number(e) - hour) * 60)
        return `${hour}h` + (min ? ` ${min}m` : '')
      }}
      isInteractive
      padAngle={0.7}
      activeOuterRadiusOffset={8}
      colors={colorList}
      borderWidth={4}
      borderColor={{
        from: 'color',
      }}
      arcLinkLabel={(e) => Number(e.value).toFixed(1)}
      arcLinkLabelsTextColor="#718096"
      arcLinkLabelsTextOffset={2}
      arcLinkLabelsStraightLength={8}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
      enableArcLabels={false}
      arcLabelsTextColor={{
        from: 'color',
      }}
      legends={[
        {
          data: getValidArray(projectWorkingTimePieData).map((item) => ({
            ...item,
            id: item?.id,
            label: getProjectShortName(item?.label),
          })),
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 160,
          translateY: 0,
          itemsSpacing: 16,
          itemWidth: 100,
          padding: 0,
          itemHeight: 16,
          itemTextColor: '#2D3748',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 16,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  )
}

export default observer(ProjectWorkingTimeChart)
