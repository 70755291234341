import { convertDateToString, formatTimeToHourMinute } from 'utils/commonUtils'
import { getFormattedTimeDate } from 'utils/dateFormatUtils'

export function formatStartEndTime(startDate: string, startTime: string, endDate: string, endTime: string) {
  const startFormattedDate = convertDateToString(new Date(startDate))
  const endFormattedDate = convertDateToString(new Date(endDate))
  const startFormattedTime = formatTimeToHourMinute(startTime)
  const endFormattedTime = formatTimeToHourMinute(endTime)
  return `${startFormattedTime} ${startFormattedDate} - ${endFormattedTime} ${endFormattedDate}`
}

export function combineStartDateTimeEndDateTime(startDate: Date, endDate: Date){
  return getFormattedTimeDate(startDate) + ' - ' + getFormattedTimeDate(endDate)
}

export function parseStartEndTime(startEndTime: string) {
  if (!startEndTime) return {}

  const [start, end] = startEndTime.split(' - ')
  const [startTime, startDateStr] = start?.split(' ') || []
  const [endTime, endDateStr] = end?.split(' ') || []

  const parseDate = (dateStr: string | undefined): Date | undefined => {
    if (!dateStr) return undefined
    const parts = dateStr.split('/')
    if (parts.length !== 3) return undefined
    const [day, month, year] = parts
    const date = new Date(`${year}-${month}-${day}`)
    return isNaN(date.getTime()) ? undefined : date
  }

  const startDate = parseDate(startDateStr)
  const endDate = parseDate(endDateStr)

  return {
    startDate,
    startTime: startTime || undefined,
    endDate,
    endTime: endTime || undefined,
  }
}
