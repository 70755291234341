import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/constants'
import { ILeave } from 'types/leave'
import { IUserLeaveListWithPaginationRequest } from './constants'
// import { dummyLeaves } from 'data/dummyLeaves'

interface IUserLeaveListWithPaginationResponse {
  success: boolean
  leaves: ILeave[]
  count: number
}
// Open 4, 12 to add dummy leaves to local storage
// localStorage.setItem('dummyLeaves', JSON.stringify(dummyLeaves));

// Just when use dummy leaves on local storage
const dummyLeaves = JSON.parse(localStorage.getItem('dummyLeaves'))

export async function getUserLeaveListWithPaginationAPI(
  payload?: IUserLeaveListWithPaginationRequest
): Promise<ISuccessResponse<IUserLeaveListWithPaginationResponse>> {
  const page = payload?.page || 1
  const perPage = payload?.perPage || 10

  let filteredLeaves = dummyLeaves

  const total = filteredLeaves.length
  const start = (page - 1) * perPage
  const end = start + perPage
  const paginatedLeaves = filteredLeaves.slice(start, end)

  const response: ISuccessResponse<IUserLeaveListWithPaginationResponse> = {
    success: true,
    data: {
      leaves: paginatedLeaves,
      count: total,
    },
    message: 'Fetched leave list successfully',
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response)
    }, 500)
  })
}

export async function getUserLeaveListAPI(): Promise<ISuccessResponse<ILeave[]>> {
  try {
    const url = APIRouters.leaveManagement.list.value
    return apiV2.get(url)
  } catch (error) {
    throw error
  }
}
