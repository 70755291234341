import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { IUserNameResponse } from 'API/user/constants'
import Button from 'components/Button'
import DatePicker from 'components/DatePickerOld'
import RadioButton from 'components/RadioButton'
import CreatableSelect from 'components/Select/ReactSelect'
import { Field } from 'formik'
import map from 'lodash/map'
import { IOption } from 'types/user'
import { ICreatableOption, IProject } from 'constants/schema'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateUserOptions } from 'utils/user'

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    fontSize: 16,
  },
})

interface IFilterFormOfPaymentProps {
  users?: IUserNameResponse[]
  paymentCategories?: ICreatableOption[]
  projects?: IProject[]
  classes?: Record<string, string>
  dirty?: boolean
  isSubmitting?: boolean
  handleSubmit?: React.FormEventHandler<HTMLFormElement>
  values?: any
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
}

const FilterFormOfPayment = (props: IFilterFormOfPaymentProps) => {
  const { classes, users, projects, paymentCategories, dirty, isSubmitting, handleSubmit, values, setFieldValue } =
    props
  const paymentCategoriesOptions = createOptionsOfReactSelectFromDB(paymentCategories)
  const projectsOptions = createOptionsOfReactSelectFromDB(projects)
  const { adminPaymentStore } = useStores()
  let newUsers = users
  if (values?.project) {
    newUsers = users.filter((item) => {
      const projectIds: string[] = map(item.projects, (project) => project?.toString())
      return projectIds.includes(values.project.value)
    })
  }
  useEffect(() => {
    setFieldValue('user', null)
  }, [values.project])
  const usersOptions: IOption[] = generateUserOptions(newUsers)

  return (
    <form onSubmit={handleSubmit}>
      <div className="container-fluid">
        <div className="box box-default">
          <div className="box-header">Filter</div>
          <div className="box-divider" />
          <div className={`box-body`}>
            <div className="container-fluid">
              <div className="fields">
                <div className="field">
                  <Field
                    name="project"
                    label="Project"
                    className={classes.textField}
                    margin="normal"
                    component={CreatableSelect}
                    options={projectsOptions}
                    isClearable={false}
                  />
                </div>
                <div className="field">
                  <Field
                    name="user"
                    label="User"
                    className={classes.textField}
                    margin="normal"
                    component={CreatableSelect}
                    options={usersOptions}
                    isClearable={false}
                  />
                </div>
              </div>
              <div className="fields">
                <div className="field">
                  <Field
                    name="category"
                    label="Category"
                    className={classes.textField}
                    margin="normal"
                    component={CreatableSelect}
                    options={paymentCategoriesOptions}
                    isClearable={false}
                  />
                </div>
                <div className="field">
                  <Field
                    name="date"
                    label="Date*"
                    className={classes.textField}
                    margin="normal"
                    component={DatePicker}
                  />
                </div>
                <div className="field radio-button">
                  <Field
                    name="includedRequest"
                    label="Included Request Of Payment"
                    className={classes.textField}
                    margin="normal"
                    component={RadioButton}
                  />
                </div>
              </div>
              <div className="submit-option">
                <Button isLoading={adminPaymentStore.isPaymentLoading} type="submit" disabled={!dirty && isSubmitting}>
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default withStyles(styles)(FilterFormOfPayment)
