import { ChakraProvider } from '@chakra-ui/react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { Provider } from 'mobx-react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { FlagsProvider } from 'react-unleash-flags'
import theme from 'theme'
import 'react-toastify/dist/ReactToastify.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import App from './containers/App'
import './styles/index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import * as serviceWorker from './serviceWorker'
import { rootStore } from './store/rootStore'

const isAdmin = window?.localStorage?.getItem('userRole') === 'admin'
const primaryColor = isAdmin ? '#2D3748' : '#1A365D'
const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: primaryColor,
      dark: primaryColor,
    },
    secondary: {
      main: '#4D5DFB',
    },
  },
  typography: {
    useNextVariants: true,
  },
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onerror: false,
      onunhandledrejection: false,
    }),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.npm_package_version,
  tracesSampleRate: 1.0,
})

const featureFlagConfig = {
  appName: process.env.REACT_APP_APP_NAME,
  host: process.env.REACT_APP_FEATURE_FLAG_API_URL,
  instanceId: process.env.REACT_APP_FEATURE_FLAG_API_ACCESS_TOKEN,
}

ReactDOM.render(
  <BrowserRouter>
    <Provider {...rootStore}>
      <ChakraProvider resetCSS={false} theme={theme}>
        <MuiThemeProvider theme={customTheme}>
          <FlagsProvider config={featureFlagConfig}>
            <App />
          </FlagsProvider>
          <ToastContainer autoClose={3000} theme="colored" />
        </MuiThemeProvider>
      </ChakraProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
