import { getFormattedTimeDate } from 'utils/dateFormatUtils'
import { ELeaveRequestStatus } from '../../../constants/enum'
import { ILeaveAdmin } from '../../../constants/schema'

export function convertLeaveDataToTableFormat(leaveData: ILeaveAdmin[]) {
  return leaveData.map(function (leaver: ILeaveAdmin) {
    let approverName = ''
    if (leaver.status === ELeaveRequestStatus.APPROVED || leaver.status === ELeaveRequestStatus.REJECTED) {
      approverName = leaver.approverName
    }

    return {
      dateCreated: leaver.createdAt,
      timeRange: getFormattedTimeDate(leaver.startDate ?? '') + '\n- ' + getFormattedTimeDate(leaver.endDate ?? ''),
      user: leaver.fullName,
      approver: approverName,
      leaveType: leaver.reason,
      status: leaver.status,
    }
  })
}
