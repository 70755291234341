export const leaveManagement = {
  value: '/leave',
  list: {
    value: '/leave/list',
  },
  create: {
    value: '/leave/create',
  },
  update: {
    value: '/leave/update',
  },
  delete: {
    value: '/leave/delete',
  },
}
