import React, { useEffect, useState } from 'react'
import { HStack, FormControl, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import MultipleSelect from 'components/MultipleSelect'
import SwitchControl from 'components/SwitchControl/SwitchControlWithTwoLabel'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { ETypeOfFilter } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { generateProjectOptions, generateUserOptions } from 'utils/user'
import { IReportFilterWithProjectFormData, IReportFilterWithUserFormData, workingPeriodOptions } from '../../constants'
import { IReportFilterFormProps } from '../../constants'

type ReportFormData = IReportFilterWithProjectFormData | IReportFilterWithUserFormData

const ReportFilter = (props: IReportFilterFormProps) => {
  const {
    projectNameList,
    userNameList,
    reportWithUserHandler,
    reportWithProjectHandler,
    isAnalysisLoading,
    initSelectedProject,
    initSelectedUser,
  } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const formValues = getValues()
  const { selectedProject, selectedUser, isFilterByProject } = formValues
  const defaultFilterType = isFilterByProject ? ETypeOfFilter.FILTER_BY_PROJECT : ETypeOfFilter.FILTER_BY_USER
  const [typeOfFilter, setTypeOfFilter] = useState(defaultFilterType)
  let newUserNameList = userNameList
  let newProjectNameList = projectNameList

  if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT && selectedProject) {
    newUserNameList = getValidArray(userNameList).filter((user) => {
      const projectIds = user?.projects ?? []
      return projectIds.includes(selectedProject.value)
    })
  }

  // Get projects of user
  function userProjects(userId: string){
    const userData = getValidArray(userNameList).find((user) => String(user.id) === String(userId))
    const projectIdsData = userData?.projects ?? []
    return getValidArray(projectNameList).filter((project) => projectIdsData.includes(project.id))
  }

  if (typeOfFilter === ETypeOfFilter.FILTER_BY_USER) {
    if (initSelectedUser && initSelectedUser.length > 0) {
      newProjectNameList = userProjects(initSelectedUser[0].value)
    } else if (selectedUser) {
      newProjectNameList = userProjects(selectedUser.value)
    }
  }

  function restoreSelectionFromInitialValues() {
    if (typeOfFilter === ETypeOfFilter.FILTER_BY_USER && selectedProject === null && selectedUser) {
      setValue('selectedUser', initSelectedUser[0])
      setValue('selectedProject', initSelectedProject)
      return
    }

    if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT && selectedUser === null && selectedProject) {
      setValue('selectedProject', initSelectedProject[0])
      setValue('selectedUser', initSelectedUser)
    }
  }
  restoreSelectionFromInitialValues()

  const projectOptions: IOption[] = generateProjectOptions(newProjectNameList)
  const userOptions: IOption[] = generateUserOptions(newUserNameList)

  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  function changeFilterTypeHandler(value: string) {
    setValue('selectedUser', null)
    setValue('selectedProject', null)
    setValue('isFilterByProject', !value)
    if (value) {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_USER)
    } else {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_PROJECT)
    }
  }

  function renderFilterOption() {
    if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT) {
      return (
        <React.Fragment>
          <InputDropdown
            name="selectedProject"
            placeHolder="Select Project"
            optionsData={projectOptions}
            isClearable={false}
          />
          <MultipleSelect
            name="selectedUser"
            key="selectedUser"
            placeholder="Select Users"
            options={userOptions}
            selectedEntityName="Users"
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <InputDropdown name="selectedUser" placeHolder="Select User" optionsData={userOptions} isClearable={false} />
        <MultipleSelect
          name="selectedProject"
          key="selectedProject"
          placeholder="Select Projects"
          options={projectOptions}
          selectedEntityName="Projects"
        />
      </React.Fragment>
    )
  }

  function handleButtonClick() {
    const data = getValues()
    if (!data.selectedProject || !data.selectedUser) {
      setValue('selectedUser', null)
      setValue('selectedProject', null)
      return
    }
    function submitHandler(formData: ReportFormData) {
      if (isFilterByProject) {
        reportWithProjectHandler(formData as IReportFilterWithProjectFormData)
      } else {
        reportWithUserHandler(formData as IReportFilterWithUserFormData)
      }
    }
    handleSubmit(submitHandler)()
  }

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }} paddingY={'16px'}>
      <VStack width="inherit" paddingLeft={'16px'}>
        <SwitchControl
          name="isFilterByProject"
          leftLabel="Filter By: User"
          rightLabel="Project"
          handleOnChange={() => changeFilterTypeHandler(isFilterByProject)}
        />
        <HStack width="inherit">
          <HStack width="inherit">
            {renderFilterOption()}
            <InputDropdown
              name="period"
              placeHolder="Select Period"
              optionsData={workingPeriodOptions}
              isClearable={false}
              borderColor="#4D5DFB"
            />
            <FormControl>
              <Controller
                name="date"
                control={control}
                render={({ field: { value } }) => (
                  <DatePicker
                    selectedDate={value}
                    onChange={pickDate}
                    style={{
                      border: '1px solid #4D5DFB',
                      borderColor: '#4D5DFB',
                    }}
                  />
                )}
              />
            </FormControl>
          </HStack>
          <HStack paddingRight={'16px'}>
            <Button
              height="44px"
              rightIcon="arrow-right-line-white.svg"
              isLoading={isAnalysisLoading}
              disabled={isAnalysisLoading}
              onClick={handleButtonClick}>
              View Analytics
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default ReportFilter
