import { handleError } from 'API/error'
import { getAllNameProjectListOfAdminAPI } from 'API/project'
import { getProjectHistoryAPI, getUserHistoryAPI } from 'API/timesheet'
import { getAllNameUserListAPI } from 'API/user'
import RadioButton from 'components/RadioButton/RadioButtonWithoutFormik'
import get from 'lodash/get'
import React from 'react'
import ProjectFilterForm from './Projects/FilterForm'
import ProjectTable from './Projects/Table'
import UserFilterForm from './Users/FilterForm'
import UserTable from './Users/Table'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import EmptyContentBox from 'components/EmptyContentBox'
import { Messages } from 'constants/index'
import LoadingChakra from 'components/LoadingChakra'

class History extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMode: 'project',
      projects: [],
      users: [],
      isReportLoading: false,
      projectHistoryData: undefined,
      userHistoryData: [],
      filters: {}
    }
  }

  componentDidMount() {
    window.sessionStorage.setItem('subPageTitle', ETimesheetSubTitle.HISTORY)
    window.dispatchEvent(new Event('storage'))
    this.getListProjectsHandler()
    this.getListUsersHandler()
    this.initFromQueryParams()
  }

  initFromQueryParams = () => {
    const params = new URLSearchParams(window.location.search)
    const selectedMode = params.get('selectedMode') || 'project'
    const filters = {}

    if (selectedMode === 'project') {
      const project = params.get('project')
      if (project) filters.project = project
      this.setState({ selectedMode, filters }, () => {
        if (Object.keys(filters).length > 0) {
          this.generateProjectHistory(filters)
        }
      })
    } else {
      const user = params.get('user')
      if (user) filters.user = user
      this.setState({ selectedMode, filters }, () => {
        if (Object.keys(filters).length > 0) {
          this.generateUserHistory(filters)
        }
      })
    }
  }

  getListProjectsHandler = async () => {
    try {
      const response = await getAllNameProjectListOfAdminAPI(EConditionGetList.ALL)
      this.setState({
        projects: response?.data?.projects
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'getListProjectsHandler')
    }
  }

  getListUsersHandler = async () => {
    try {
      const response = await getAllNameUserListAPI(EConditionGetList.ALL)
      this.setState({
        users: response?.data?.users
      })
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'getListUsersHandler')
    }
  }

  switchMode = () => {
    const { selectedMode } = this.state
    let nextMode = selectedMode === 'user' ? 'project' : 'user'
    this.setState(
      {
        selectedMode: nextMode,
        filters: {},
        projectHistoryData: undefined,
        userHistoryData: []
      },
      () => {
        this.updateQueryString()
      }
    )
  }

  updateQueryString = () => {
    const { selectedMode, filters } = this.state
    const params = new URLSearchParams()
    params.set('selectedMode', selectedMode)
    Object.keys(filters).forEach((key) => {
      params.set(key, filters[key])
    })
    const newUrl = window.location.pathname + '?' + params.toString()
    window.history.replaceState({}, '', newUrl)
  }

  renderOptionFilter = () => {
    const { selectedMode, projects, users, filters } = this.state

    if (selectedMode === 'project') {
      return (
        <ProjectFilterForm
          isReportLoading={this.state.isReportLoading}
          projects={projects}
          filterSubmitHandler={this.generateProjectHistory}
          initialValues={filters}
        />
      )
    } else {
      return (
        <UserFilterForm
          isReportLoading={this.state.isReportLoading}
          users={users}
          filterSubmitHandler={this.generateUserHistory}
          initialValues={filters}
        />
      )
    }
  }

  generateProjectHistory = async (values) => {
    try {
      this.setState({ isReportLoading: true })
      const parsedValues = { ...values }
      if (values?.project) {
        parsedValues.project = get(values, 'project.value', values.project)
      }
      const response = await getProjectHistoryAPI(parsedValues)
      this.setState(
        {
          projectHistoryData: response?.data?.projectHistoryData,
          filters: parsedValues
        },
        () => {
          this.updateQueryString()
        }
      )
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'generateProjectHistory', false, true)
    } finally {
      this.setState({ isReportLoading: false })
    }
  }

  generateUserHistory = async (values) => {
    try {
      this.setState({ isReportLoading: true })
      const parsedValues = { ...values }
      if (values?.user) {
        parsedValues.user = get(values, 'user.value', values.user)
      }
      const response = await getUserHistoryAPI(parsedValues)
      this.setState(
        {
          userHistoryData: response?.data?.userHistoryData,
          filters: parsedValues
        },
        () => {
          this.updateQueryString()
        }
      )
    } catch (error) {
      handleError(error, 'src/containers/TimeSheet/TimeSheetAdmin/History/index.js', 'generateUserHistory')
    } finally {
      this.setState({ isReportLoading: false })
    }
  }

  renderBody = () => {
    const { selectedMode, projectHistoryData, userHistoryData } = this.state
    let content
    if (selectedMode === 'project') {
      content = <ProjectTable data={projectHistoryData} />
    } else {
      content = <UserTable data={userHistoryData} />
    }

    return (
      <div className="timesheet-content">
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Summary</div>
            <div className="box-divider" />
            <div className={`box-body`}>{content}</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { selectedMode } = this.state
    let isModeUser = selectedMode === 'user'
    return (
      <div className="timesheet-page">
        <div className="options-group">
          <RadioButton label="Choose Mode User" value={isModeUser} clickHandler={this.switchMode} />
          {this.renderOptionFilter()}
        </div>
        <LoadingChakra isLoading={this.state.isReportLoading}>
          <div className="body">
            {!this.state.projectHistoryData && !this.state.userHistoryData?.length ? (
              <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
            ) : (
              this.renderBody()
            )}
          </div>
        </LoadingChakra>
      </div>
    )
  }
}

export default History
