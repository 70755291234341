import { useState } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import { History } from 'history'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useFlag } from 'react-unleash-flags'
import { getFormattedDateWith_DDMMYYYY } from 'utils/dateFormatUtils'
import Table from '../../../components/Table'
import MoreDropdown from '../../../components/Table/DesktopTable/components/MoreDropdown'
import { limitItemPerPage } from '../../../constants'
import { maxMobileSize } from '../../../constants/common'
import { ELeaveRequestStatus } from '../../../constants/enum'
import { frontendRoutes } from '../../../constants/routes'
import { ILeaveAdmin } from '../../../constants/schema'
import { sampleData } from '../../../data/dummyLeaves'
import { backgroundGrey200 } from '../../../theme/globalStyles'
import { ILeaveFilter, ITableLeaveData } from '../../../types/leave'
import { getValidArray } from '../../../utils/commonUtils'
import { useStores } from '../../../utils/hooks/useStores'
import { getHeaderList } from './constant'
import { convertLeaveDataToTableFormat } from './container'
import styles from './adminLeaveList.module.scss'

const AdminLeaveList = () => {
  const featureFlag = useFlag(process.env.REACT_APP_FEATURE_FLAG_LEAVE_REQUEST)
  const history: History = useHistory()
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminLeaveManagementStore } = useStores()
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })

  const { userLeaveList, count } = adminLeaveManagementStore

  const [filter, setFilter] = useState<ILeaveFilter>(initialFilter ?? {})

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex: pageIndex,
    pageSize: limitItemPerPage,
    tableLength: 200,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })

      adminLeaveManagementStore.getUserLeaveListWithPagination(history, filter)
    },
  }

  function actionAccept(leaver) {
    return function () {}
  }

  function actionReject(leaver) {
    return function () {}
  }

  const dataInTable = convertLeaveDataToTableFormat(getValidArray<ILeaveAdmin>(sampleData)).map(function (
    leaver: ITableLeaveData
  ) {
    return {
      ...leaver,
      dateCreated: getFormattedDateWith_DDMMYYYY(leaver.dateCreated),
      actionsAccepted:
        leaver.status === ELeaveRequestStatus.PENDING ? (
          <MoreDropdown isAccepted={true} detailActionHandler={actionAccept(leaver)} />
        ) : null,
      actionsRejected:
        leaver.status === ELeaveRequestStatus.PENDING ? (
          <MoreDropdown isReject={true} detailActionHandler={actionReject(leaver)} />
        ) : null,
    }
  })

  if (!featureFlag || !featureFlag?.enabled) {
    return null
  }

  return (
    <Box
      className={styles.leaveContainer}
      background="white"
      padding={6}
      borderRadius="6px"
      border={`1px solid ${backgroundGrey200}`}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }}>
          <InputGroup
            border={`1px solid ${backgroundGrey200}`}
            borderRadius="6px"
            width={{ base: '100%', lg: '540px' }}
            background="white">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input type="search" placeholder="Search request by name..." onChange={() => {}} />
          </InputGroup>
        </HStack>
      </Flex>
      <Table
        headerList={getHeaderList(isMobile)}
        isLoading={false}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
    </Box>
  )
}

export default observer(AdminLeaveList)
